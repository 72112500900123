import {Space} from "antd";
import {RankImg} from "./RankImg";
import {Ranks} from "../Protocol/Types";


export function RankSummary({ranks}: {ranks: Ranks}) {
    return <Space direction={"horizontal"}>
        <RankImg rank={ranks["RANKEDDUEL"]}/>
        <RankImg rank={ranks["RANKEDDOUBLES"]}/>
        <RankImg rank={ranks["RANKEDSTANDARD"]}/>
    </Space>
}
