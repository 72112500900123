import {useEffect, useState} from "react";

export default function LifetimeWins({trackerPath}: {trackerPath?: string}) {
    const [wins, setWins] = useState("?")
    useEffect(() => {
        if (trackerPath) {
            fetch(`https://overlay.saks.me/v1/tracker-stats/${trackerPath}`)
                .then(r => r.json())
                .then(d => {
                    setWins(d.lifetimeWins)
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }, [trackerPath, setWins])
    return <span>{wins}</span>
}
