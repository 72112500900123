import "./RankImg.css";
import {Tooltip} from "antd";
import {PlaylistRank} from "../Protocol/Types";
import {playlistName} from "../Pages/SessionStats/SessionStats";

export type RankImgProps = {
    rank: PlaylistRank
}

export function RankImg({rank: {tier, division, mmr, playlist}}: RankImgProps) {
    let divText = "";
    if (tier !== 0) {
        if (division === 0) {
            divText = "I"
        } else if (division === 1) {
            divText = "II"
        } else if (division === 2) {
            divText = "III"
        } else if (division === 3) {
            divText = "IV"
        }
    }
    const titleValue = <>{playlistName(playlist)}<br/>{mmr.toFixed(1)}</>
    return (<Tooltip title={titleValue}>
        <span className="rank-image">
            <img src={`tiers/${tier}.png`} alt="" width="32px" height="32px"/>
            <span className="rank-image-division">{divText}</span>
    </span>
    </Tooltip>);
}
