import {Client, StompSubscription} from "@stomp/stompjs";
import {Frame} from "../Protocol/Types";

export function getBrokerUrl(): string {
    return `wss://overlay.saks.me/ws`;
}

export type MessageSubscription<T> = {
    type: string
    destination: string
    onMessage: (message: T) => void
}

export interface BrokerSubscription<T> {
    messageSubs: { [key: string]: MessageSubscription<T>[] }
    stompSub: StompSubscription | null
}

class TheBroker {
    private readonly subscriptions: { [key: string]: BrokerSubscription<any> } = {};
    private readonly client = new Client();

    constructor() {
        this.client.brokerURL = getBrokerUrl();
        this.client.onConnect = () => {
            this.subscribeAll();
        };
        this.client.activate();
    }

    private subscribeAll() {
        for (const destination of Object.keys(this.subscriptions)) {
            this.doSubscription(destination)
        }
    }

    private doSubscription<T>(destination: string) {
        if (this.client.connected) {
            this.subscriptions[destination].stompSub = this.client.subscribe(destination, (message) => {
                const frame: Frame<T> = JSON.parse(message.body)
                const subs = this.subscriptions[destination].messageSubs[frame.type] || []
                for (const sub of subs) {
                    sub.onMessage(frame.data)
                }
            })
        }
    }

    private doUnsubscription(sub: MessageSubscription<any>) {
        const brokerSub = this.subscriptions[sub.destination]
        const messageSubs = brokerSub.messageSubs[sub.type];
        const msgSubIdx = messageSubs.indexOf(sub)
        if (msgSubIdx !== -1) {
            messageSubs.splice(msgSubIdx, 1)
            if (messageSubs.length === 0) {
                delete brokerSub.messageSubs[sub.type];
            }
        }
        // Unsub from STOMP destination if this was the last message type sub
        if (brokerSub.stompSub !== null && this.client.connected && Object.keys(brokerSub.messageSubs).length === 0) {
            brokerSub.stompSub.unsubscribe()
            brokerSub.stompSub = null;
        }
    }

    subscribe<T>(destination: string, type: string, onMessage: (message: T) => void): MessageSubscription<T> {
        if (!this.subscriptions[destination]) {
            this.subscriptions[destination] = {messageSubs: {}, stompSub: null}
            this.doSubscription(destination)
        }
        if (!this.subscriptions[destination].messageSubs[type]) {
            this.subscriptions[destination].messageSubs[type] = []
        }
        const messageSub = {destination, type, onMessage};
        this.subscriptions[destination].messageSubs[type].push(messageSub)
        return messageSub
    }

    unsubscribe(sub: MessageSubscription<any> | null) {
        if (sub == null) return
        this.doUnsubscription(sub)
    }
}

const BROKER = new TheBroker();

export default BROKER;
