import {Button, Card, Col, Popover, Row, Statistic, Switch} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {MinusOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import "./ChallengeTracker.css";
import Broker from "../../Util/Broker";
import {MatchEnd} from "../../Protocol/Types";

export function ChallengeTracker() {
    const [games, setGames] = useState(() => getFromStorage("challengeTrackerGames", 0))
    const [gamesChallenge, setGamesChallenge] = useState(() => getFromStorage("challengeTrackerGamesChallenge", 20))
    const [wins, setWins] = useState(() => getFromStorage("challengeTrackerWins", 0))
    const [winsChallenge, setWinsChallenge] = useState(() => getFromStorage("challengeTrackerWinsChallenge", 10))
    const [enabled, setEnabled] = useState(() => window.localStorage.getItem("challengeTrackerEnabled") === "true")
    useEffect(() => {
        window.localStorage.setItem("challengeTrackerGames", "" + games);
        window.localStorage.setItem("challengeTrackerGamesChallenge", "" + gamesChallenge);
        window.localStorage.setItem("challengeTrackerWins", "" + wins);
        window.localStorage.setItem("challengeTrackerWinsChallenge", "" + winsChallenge);
        window.localStorage.setItem("challengeTrackerEnabled", "" + enabled);
    }, [games, gamesChallenge, wins, winsChallenge, enabled]);

    useEffect(() => {
        if (enabled) {
            const sub = Broker.subscribe("/rocket-league", "MATCH_END", (matchEnd: MatchEnd) => {
                setGames(games => games + 1)
                if (matchEnd.selfWin) {
                    setWins(wins => wins + 1)
                }
            });

            return function cleanup() {
                Broker.unsubscribe(sub);
            }
        }

    }, [enabled]);

    return <Card title="Challenge tracker"
                 extra={<Switch checked={enabled} onChange={setEnabled} title={"Challenge tracker enabled"}/>}
                 style={{height: "100%", width: "100%"}}>
        <Row align={"middle"} justify={"center"} style={{textAlign: "center"}}
             className={games >= gamesChallenge ? 'challenge-highlight' : ''}>
            <Col span={4}>
                <EditableStatistic title={"Games"} value={games} onChange={setGames}/>
            </Col>
            <Col span={4}>
                <EditableStatistic title={"Challenge"} value={gamesChallenge} onChange={setGamesChallenge}/>
            </Col>
        </Row>
        <Row align={"middle"} justify={"center"} style={{textAlign: "center"}}
             className={wins >= winsChallenge ? 'challenge-highlight' : ''}>
            <Col span={4}>
                <EditableStatistic title={"Wins"} value={wins} onChange={setWins}/>
            </Col>
            <Col span={4}>
                <EditableStatistic title={"Challenge"} value={winsChallenge} onChange={setWinsChallenge}/>
            </Col>
        </Row>
    </Card>
}

function getFromStorage(key: string, defaultValue: number): number {
    const item = window.localStorage.getItem(key);
    if (item) {
        return +item
    }
    return defaultValue
}

function EditableStatistic({
    title,
    onChange,
    value
}: { value: number, title: string, onChange?: (value: number) => void }) {
    const increment = useCallback(() => {
        const newValue = value + 1
        if (newValue <= 50 && onChange) {
            onChange(newValue)
        }
    }, [onChange, value])
    const decrement = useCallback(() => {
        const newValue = value - 1
        if (newValue >= 0 && onChange) {
            onChange(newValue)
        }
    }, [onChange, value])
    const reset = useCallback(() => {
        if (onChange) {
            onChange(0)
        }
    }, [onChange])
    return <Statistic
        value={value}
        title={<Popover trigger='click' content={
            <Button.Group>
                <Button type={'text'} size={'small'} icon={<MinusOutlined/>} onClick={decrement}/>
                <Button type={'text'} size={'small'} icon={<ReloadOutlined/>} onClick={reset}/>
                <Button type={'text'} size={'small'} icon={<PlusOutlined/>} onClick={increment}/>
            </Button.Group>
        } className={'statistic-title'}>{title}</Popover>}
        precision={0}/>
}
