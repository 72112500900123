import React from 'react';
import './App.css';
import {Col, ConfigProvider, Layout, Menu, Row, theme} from "antd";
import {NowPlaying} from "./Pages/NowPlaying/NowPlaying";
import {PlayCircleFilled} from "@ant-design/icons";
import {SessionStats} from "./Pages/SessionStats/SessionStats";
import {ChallengeTracker} from "./Pages/ChallengeTracker/ChallengeTracker";

function App() {
    return (
        <ConfigProvider theme={{algorithm: theme.darkAlgorithm}}>
            <Layout style={{height: "100%"}}>
                <Layout.Header>
                    <img src={"/RocketLeagueLogoText.png"} className={"logo"} alt={""}/>
                    <Menu theme={"dark"} mode={"horizontal"} selectedKeys={["now-playing"]}>
                        <Menu.Item key={"now-playing"} icon={<PlayCircleFilled/>}>Now Playing</Menu.Item>
                    </Menu>
                </Layout.Header>
                <Layout.Content style={{padding: '50px 50px'}}>
                    <Row gutter={16}>
                        <Col span={18}><NowPlaying/></Col>
                        <Col span={6}>
                            <Row gutter={[0, 14]}>
                                <SessionStats/>
                                <ChallengeTracker/>
                            </Row>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        </ConfigProvider>
    );
}

export default App;
